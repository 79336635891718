import { DEV_MODE_ExperimentOverrides } from '@hungryroot/experiments';
import { ApiOriginMetaTag } from './ApiOriginMetaTag';

export function DevModeHead() {
  return (
    <>
      <ApiOriginMetaTag />
      <DEV_MODE_ExperimentOverrides />
    </>
  );
}
